<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="10" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select @input="setCurrentDate">
					<template v-slot:top>
						<div class="d-flex flex-wrap pt-1 pb-1" style="gap:16px" ref="tabletopbar">
							<v-btn-toggle mandatory dense v-model="filter1" @change="loadData">
								<v-btn>全部</v-btn>
								<v-btn>未约评估</v-btn>
								<v-btn>未约镜检</v-btn>
								<v-btn>未约解读</v-btn>
								<v-btn>可约门诊</v-btn>
							</v-btn-toggle>
							<v-text-field placeholder="姓名/身份证号/手机号" v-model.trim="psearch" clearable dense hide-details style="flex: 0 1 auto" class="ml-4"
								append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.apntEvalDate="{ item }">
						{{formatDate(item.apntEvalDate)}}
					</template>
					<template v-slot:item.apntOprtDate="{ item }">
						{{formatDate(item.apntOprtDate)}}
					</template>
					<template v-slot:item.apntIntpDate="{ item }">
						{{formatDate(item.apntIntpDate)}}
					</template>
					<template v-slot:item.idcard="{ item }">
						{{getMaskedIdcard(item)}}
					</template>
					<template v-slot:item.age="{ item }">
						{{getAge(item)}}
					</template>
					<template v-slot:item.opts="{ item }">
						{{getOptsStr(item)}}
					</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="height:100%;" class="overflow-y-auto">
					<Calendar style="width:calc(100% - 16px)" ref="calendar"
						:permissions="permissions"
						:showdialog="onShowDialog"
						:currentdate="currentdate"
						:orderid="selected[0] ? selected[0]._id : ''"
						:attach="attach"
						@aftermake="fetchData"
						@aftercancel="fetchData">
						<template v-slot:details>
							<v-text-field label="姓名" readonly v-model="cname" dense hide-details/>
						</template>
					</Calendar>
	            </div>
			</pane>
		</splitpanes>
	    <v-snackbar color="error" v-model="snackbar">
			{{errmsg}}
			<template v-slot:action="{ attrs }">
				<v-btn outlined small v-bind="attrs" @click="snackbar = false">关闭</v-btn>
			</template>
		</v-snackbar>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import Calendar from '@/components/Calendar3.vue'

    export default {
		name: 'Appointment',
		props:['phase'],
        data() {
            return {
                authed: false,
				apntdeadline: {},
				permissions: [],
                headers: [
                    {text:'姓名', value:'pii.username', width:120, class:"pl-0", cellClass:"pl-0"},
                    {text:'性别', value:'pii.gender', width:40, sortable: false, class:"px-0", cellClass:"px-0"},
                    {text:'年龄', value:'age', width:40, sortable: false, class:"px-0", cellClass:"px-0"},
                    {text:'预约评估日期', value:'apntEvalDate', width:110, class:"pl-0", cellClass:"pl-0"},
                    {text:'预约镜检日期', value:'apntOprtDate', width:110, class:"pl-0", cellClass:"pl-0"},
                    {text:'预约解读日期', value:'apntIntpDate', width:110, class:"pl-0", cellClass:"pl-0"},
                    {text:'套餐内容', value:'opts', width:180, sortable: false},
                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'代理人', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                    {text:'病案号', value:'pid', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				cname: '',
				currentdate: formatDate(Date.now()),
				snackbar: false,
				errmsg: '',
				filter1: 0,
				options: {},
				totalItems: 0,
				psearch: '',
				attach: {},
				tableheight: undefined,
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.cname = newitem[0].pii.username;
			}
		},
		created() {
			this.permissions = ['apnt'];
			this.formatTime = formatTime;
			this.formatDate = formatDate;
//			const workset = JSON.parse(window.sessionStorage.getItem('workset'));
//			this.apntdeadline = workset.apntdeadline;
		},
        mounted() {
			this.authed = this.$hasPrivilege(['预约','代理人']);
			if (!this.authed) return;
			const db = this.$tcbapp.database();
            db.collection('workset').doc('apntdeadline').get().then(res => {
				this.apntdeadline = res.data[0];
            }).catch(console.error);
			this.fetchData();
		},
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.$hasPrivilege('预约')) {
					//admin也有此权限
				} else {
					const u = this.$getCurrentUser();
					filter.push({consultantUsername: u.username});
				}
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				filter.push({hide:_.neq(true)});
				filter.push({completed:_.neq(true)});
				let f;
				if (this.filter1 === 4) {
					f = filter.concat([{category:'clinic'}]);
				} else {
					filter.push({"payment.actual":_.exists(true)});
					const filters1 = [
						[],
						[{apntEvalDate:_.exists(false)}],
						[{apntOprtDate:_.exists(false)}],
						[{apntIntpDate:_.exists(false)}],
					];
					f = filter.concat(filters1[this.filter1]);
				}
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
					if (this.selected.length > 0) {
						const s = this.items.find(x => x._id === this.selected[0]._id);
						this.selected = s ? [s] : [];
					}
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			/*
			testDeadlineMake(tag, date) {
				const dl = this.apntdeadline[tag];
				return dl && (Date.now() < offsetDayTime(date, dl[0], dl[1]));
			},
			testDeadlineCancel(tag, date) {
				const dl = this.apntdeadline[tag];
				return dl && (Date.now() < offsetDayTime(date, dl[2], dl[3]));
			},
			*/
			testDeadlineMake(tag, date, e) {
				const h = this.apntdeadline[tag]?.['预约'] || 0;
				const theTime = new Date(date).getTime() + e.starttime;
				return (theTime - Date.now()) / (3600 * 1000) > h;
			},
			testDeadlineCancel(tag, date, e) {
				const h = this.apntdeadline[tag]?.['取消预约'] || 0;
				const theTime = new Date(date).getTime() + e.starttime;
				return (theTime - Date.now()) / (3600 * 1000) > h;
			},
			async onShowDialog(date, event/*, queue*/) {
//				const supv = this.$hasPrivilege('预约');
				const supv = false;
				const item = this.selected[0];
				const dl = this.apntdeadline[event.tag];
				if (event.info) {		//有info则显示取消预约
					if (!supv) {
						if (!this.testDeadlineCancel(event.tag, date, event)) {
//							this.$dialog.message.error('已过可取消截止时间');
//							return false;
							const h = dl['取消预约'];
							const res = await this.$dialog.warning({
								title: '提示',
								text: `取消预约应提前至少${h}小时，确定要继续吗？`
							});
							if (!res) return false;
						}
					}
					return event.orderid === item._id;
				}
				if (!supv && !this.testDeadlineMake(event.tag, date, event)) {
//					this.$dialog.message.error('已过可预约截止时间');
//					return false;
					const h = dl['预约'];
					const res = await this.$dialog.warning({
						title: '提示',
						text: `预约应提前至少${h}小时，确定要继续吗？`
					});
					if (!res) return false;
				}
				switch(event.tag) {
					case 'p':
						if (item.apntEvalDate) {
							this.errmsg = '已预约' + formatTime(item.apntEvalDate);
							this.snackbar = true;
							return false;
						}
						return true;
					case 't':
					case 'bt':
						if (item.apntOprtDate) {
							this.errmsg = '已预约' + formatTime(item.apntOprtDate);
							this.snackbar = true;
							return false;
						}
						if (event.tag === 't' && item.opts.PRO !== '专家精查') {
							this.errmsg = '产品非ZT';
							this.snackbar = true;
							return false;
						} else if (event.tag === 'bt' && item.opts.PRO !== '标准精查') {
							this.errmsg = '产品非BT';
							this.snackbar = true;
							return false;
						}
						return true;
					case 'z':
						if (item.apntPthlDate) {
							this.errmsg = '已预约' + formatTime(item.apntPthlDate);
							this.snackbar = true;
							return false;
						}
						return true;
					case 'c':
						return true;
				}
				return false;
			},
			setCurrentDate() {
				this.currentdate = formatDate(new Date());
			},
		},
        components: { Splitpanes, Pane, Calendar }
    }
</script>
